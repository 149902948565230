import Navbar from "./Navbar"
import Home from "./pages/Home"
import SearchPage from "./pages/SearchPage"
import ProductPage from "./pages/ProductPage"
import OtherProductsPage from "./pages/OtherProducts"
import { Route, Routes } from "react-router-dom"
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import CartPage from "./pages/CartPage"
import Footer from './Footer';
import ShipmentForm from "./pages/ShipmentForm"
import Catalog from "./pages/Catalog"
import FAQ from "./pages/FAQ"
import EmailDialog from "./EmailDialog"
import TermsAndConditions from "./pages/TermsAndConditions"
import { isMobile, useWindowDimensions } from "./utils"

function App() {
  const [otherItems, setOtherItems] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successText, setSuccessText] = useState(null);

  const [tireToEmail, setTireToEmail] = useState(null);
  const [emailText, setEmailText] = useState(null);
  const handleErrorMessage = error => {
    if (error != null) {
      setSuccessText(null);
    }
    if (error == null) {
      setErrorMessage(null);
    } else if (typeof error === 'string' || error instanceof String) {
      setErrorMessage(error);
    } else if (error.response != null && error.response.data != null && error.response.data.text != null) {
      setErrorMessage(error.response.data.text);
    }
  };
  const { width, height } = useWindowDimensions();
  const mobile = isMobile();
  if (mobile && window.matchMedia("(orientation: landscape)").matches) {
    document.body.style.width = height + 'px';
    document.body.style.left = ((width - height) / 2) + 'px';
  }
  const extraNavStyles = {position: mobile ? 'relative' : 'sticky', top: '0px', left: '0px', zIndex: 9999999};
  const cartTotal = parseInt(cartItems.reduce((acc, curr) => acc + parseFloat(curr.quantity ?? 0), 0));

  let location = useLocation();

  React.useEffect(() => {
    if (location.pathname.includes('/success')) {
      setSuccessText(`
        Your order was successful. You will receive an emailed receipt. Check your junk folder if you do not see this email. Orders are processed within 1-2 business days. You will receive another email once your order has been processed with your tracking number. Shipping times vary. If you are local and picking up your items, call ETC to schedule an appointment. Call ETC immediately if you need to cancel your order for any reason. Orders cannot be canceled once they are processed. See our Terms and Conditions at the bottom of this website.
      `.trim());
    } else {
      setSuccessText(null);
    }
    handleErrorMessage(null);
  }, [location.pathname]);

  return <React.Fragment>
    <div style={extraNavStyles}>
      <Navbar cartTotal={cartTotal} setOtherItems={setOtherItems}/>
      {
        (!mobile && errorMessage != null) && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-center" role="alert">
          <span className="block sm:inline">{errorMessage}</span>
          <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => handleErrorMessage(null)}>
            <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
          </span>
        </div>
      }
      {
        (!mobile && successText != null) && <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded sticky text-center" role="alert">
          <span className="block pr-5">{successText}</span>
          <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => setSuccessText(null)}>
            <svg className="fill-current h-6 w-6 text-green-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
          </span>
        </div>
      }
    </div>
    {
        (mobile && errorMessage != null) &&
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-center" role="alert" style={{top: '0px', zIndex: 99999}}>
          <span className="block pr-5">{errorMessage}</span>
          <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => handleErrorMessage(null)}>
            <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
          </span>
        </div>
      }
    {
      (mobile && successText != null) &&
      <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded sticky text-center" role="alert" style={{top: '0px', zIndex: 99999}}>
        <span className="block sm:inline">{successText}</span>
        <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => setSuccessText(null)}>
          <svg className="fill-current h-6 w-6 text-green-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
        </span>
      </div>
    }
    <Routes>
      {["/", "/success", "/failure"].map((path, index) => 
          <Route path={path} element={<Home handleErrorMessage={handleErrorMessage} />} key={index} />
      )}
      <Route path="/search" element={<SearchPage cartItems={cartItems} setCartItems={setCartItems} setSuccessText={setSuccessText} setTireToEmail={setTireToEmail} setEmailText={setEmailText}/>}/>
      <Route path="/ProductPage/:itemId" element={<ProductPage setTireToEmail={setTireToEmail} setSuccessText={setSuccessText} />} />
      <Route path="/OtherProducts" element={<OtherProductsPage cartItems={cartItems} setCartItems={setCartItems} setSuccessText={setSuccessText} setTireToEmail={setTireToEmail} setEmailText={setEmailText} otherItems={otherItems}/>} />
      <Route path="/OrderPreview" element={<CartPage cartItems={cartItems} setCartItems={setCartItems} handleErrorMessage={handleErrorMessage} />} />
      <Route path="/FAQ" element={<FAQ />} />
      <Route path="/Catalog" element={<Catalog />} />
      <Route path="/ShipmentForm" element={<ShipmentForm handleErrorMessage={handleErrorMessage} />} />
      <Route path="/TermsAndConditions" element={<TermsAndConditions/>} />
    </Routes>
    <Footer/>
    <EmailDialog tireToEmail={tireToEmail} setTireToEmail={setTireToEmail} setEmailText={setEmailText} emailText={emailText}/>
  </React.Fragment>
}

export default App;
