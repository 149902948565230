import { Link } from "react-router-dom"
import React, { useEffect, useState } from "react";
import axios from 'axios';
import Select from 'react-select'
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { getImageURL, isMobile, getAPIURL } from "./utils";
import ClearIcon from '@mui/icons-material/Clear';

const cleanValue = (value) => value === 'none' ? 'None' : parseFloat(value);

const filterUniquesAndSort = (arr) => {
    if (arr == null) {
        return null;
    }
    const uniques = [...new Set(arr)];
    uniques.sort((a, b) => {
        if (a === 'None' && b === 'None') {
            return 0;
        } else if (arr === 'None') {
            return -1;
        } else if (b === 'None') {
            return 1;
        }
        return a < b ? -1 : 1;
    });
    return uniques;
};


export default function Navbar({cartTotal, setOtherItems}) {
    const mobile = isMobile();
    // const calcSpacing = (desktopSpace, mobileSpacing=12) => mobile ? mobileSpacing : desktopSpace;

    const [makeModelList, setMakeModelList] = useState([]);
    const [selectedMake, setSelectedMake] = useState(null);
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);

    const makeList = filterUniquesAndSort(makeModelList.map(obj => obj.make));
    const modelList = selectedMake == null ? [] : filterUniquesAndSort(makeModelList.filter(obj => obj.make === selectedMake).map(obj => obj.model));
    const yearList = (selectedMake == null || selectedModel == null) ? []
        : filterUniquesAndSort(makeModelList.filter(obj => obj.make === selectedMake && obj.model === selectedModel).map(obj => obj.year));

    const [specList, setSpecList] = useState([]);
    const [sectionWidth, setSectionWidth] = useState(null);
    const [aspectRatio, setAspectRatio] = useState(null);
    const [wheelDiameter, setWheelDiameter] = useState(null);

    const seatOptions = filterUniquesAndSort(specList.map(tire => tire.wheelDiameter));
    const sectionWidthOptions = wheelDiameter == null ? null : filterUniquesAndSort(specList.filter(tire => tire.wheelDiameter === wheelDiameter).map(tire => tire.sectionWidth));
    const aspectRatioOptions = (wheelDiameter == null || sectionWidth == null) ? null :
        filterUniquesAndSort(specList.filter(tire => tire.wheelDiameter === wheelDiameter && tire.sectionWidth === sectionWidth).map(tire => tire.aspectRatio));

    const [allCategories, setAllCategories] = useState([]);
    const [category, setCategory] = useState(null);

    const clearMakeModel = () => {
        setSelectedMake(null);
        setSelectedModel(null);
        setSelectedYear(null);
    };

    const resetSpecSearch = () => {
        setSectionWidth(null);
        setAspectRatio(null);
        setWheelDiameter(null);
    }

    const buildFindURL = () => {
        let url = '/search';
        let paramNames = ['make', 'model', 'year', 'seat', 'aspectRatio', 'sectionWidthMM', 'category'];
        let paramValues = [selectedMake, selectedModel, selectedYear, wheelDiameter, aspectRatio, sectionWidth, category];
        let counter = 0;
        for (var i = 0; i < paramNames.length; i++) {
            if (paramValues[i] != null && paramValues[i] !== '') {
                const startChar = (counter === 0 ? '?' : '&');
                url += startChar + paramNames[i] + '=' + paramValues[i];
                counter++;
            }
        }
        return url;
    };
    const findURL = buildFindURL();

    useEffect(() => {
        axios.get(getAPIURL('/api/allItems'))
            .then(response => {
                const allTires = response.data.tire_data;
                const otherItems = response.data.rim_and_tube_data;
                setOtherItems(otherItems);
                const categories = allTires.map(tire => tire.category);
                setAllCategories(Array.from(new Set(categories)));
                const specList = allTires.map(tire => {
                    return {
                        sectionWidth: cleanValue(tire.sectionWidthMM),
                        aspectRatio: cleanValue(tire.aspectRatio),
                        wheelDiameter: cleanValue(tire.seat)
                    }
                });
                setSpecList(specList);
            })
            .catch(error => {
                console.error("Failed to fetch tires: ", error);
            });
        axios.get(getAPIURL('/api/tires/makeModels'))
            .then(response => {
                setMakeModelList(response.data);
            })
            .catch(error => {
                console.error("Failed to fetch makes: ", error);
            });
    }, []);

    const gridClass = mobile ? "grid grid-cols-1 gap-4" : "grid grid-cols-5 gap-4";
    const getStyle = (zIndex=undefined) => {
        const styles = {width: '90%'};
        //if (zIndex != null) {
        //    styles.zIndex = zIndex;
        //}
        if (mobile) {
            styles.position = 'relative';
            styles.left = '5%';
        }
        return styles;
    }
    const cartClasses = mobile ? "items-center align-center center mb-5" : "flex items-center align-center";
    return (
        <div id="nav">
            <div className={gridClass}>
                <div className="flex flex-col">
                    <Link to='/'>
                        <img src={getImageURL('EclecticTireCompanyLogo.png')} alt="logo"/>
                    </Link>
                </div>
                <div className="flex flex-col center mt-5 mb-5">
                    <h2 className="searchLabelText" style={getStyle()}>Find Your Tires</h2>
                    <div style={getStyle(90000020)}>
                        <Select
                            options={makeList.map((name) => {return {value: name, label: name}})}
                            onChange={obj => {setSelectedMake(obj.value); setSelectedModel(null); setSelectedYear(null);}}
                            value={selectedMake == null ? null : {value: selectedMake, label: selectedMake}}
                            menuPlacement="auto"
                            menuPosition="fixed"
                            placeholder="Select a Make"
                            className="mb-2"
                        />
                    </div>
                    {
                        selectedMake != null &&
                        <div style={getStyle(90000021)}>
                            <Select
                                options={modelList.map((name) => {return {value: name, label: name}})}
                                onChange={obj => {setSelectedModel(obj.value); setSelectedYear(null); }}
                                value={selectedModel == null ? null : {value: selectedModel, label: selectedModel}}
                                styles={{'z-index': -1}}
                                menuPlacement="auto"
                                menuPosition="fixed"
                                placeholder="Select a Model"
                                className="mb-2"
                            />
                        </div>
                    }
                    {
                        selectedModel != null &&
                        <div style={getStyle(90000022)}>
                            <Select
                                options={yearList.map((name) => {return {value: name, label: name}})}
                                onChange={obj => setSelectedYear(obj.value)}
                                value={selectedYear == null ? null : {value: selectedYear, label: selectedYear}}
                                styles={{'z-index': -1}}
                                menuPlacement="auto"
                                menuPosition="fixed"
                                placeholder="Select a Year"
                                className="mb-2"
                            />
                        </div>
                    }
                    {
                        selectedMake != null && ( 
                            <Stack spacing={1} style={getStyle()} alignItems="center" justifyContent="center" direction="row">
                                <Link to='/' style={{width: '50%'}}>
                                    <Button variant="contained" style={{width: '100%', backgroundColor: '#C9D2D7', color: 'black'}} onClick={clearMakeModel}>   
                                        <ClearIcon/> Clear
                                    </Button>
                                </Link>
                                <Link to={findURL} style={{width: '50%'}}>
                                    <Button variant="contained" style={{width: '100%', backgroundColor: '#C9D2D7', color: 'black'}}>  
                                        <SearchIcon/> Search 
                                    </Button>
                                </Link>
                            </Stack>
                        )
                    }
                </div>
                <div className="flex flex-col center mt-5 mb-5">
                    <h2 className="searchLabelText" style={getStyle()}>Find by Tire Size</h2>
                    <div style={getStyle(90000010)}>
                        <Select
                            className="mb-2"
                            options={seatOptions.map(cat => {return {value: cat, label: cat}})}
                            onChange={obj => {resetSpecSearch(); setWheelDiameter(obj.value);}}
                            value={wheelDiameter == null ? null : {value: wheelDiameter, label: wheelDiameter}}
                            menuPlacement="auto"
                            menuPosition="fixed"
                            placeholder="Select Rim Size (Seat)"
                        />
                    </div>
                    {
                        sectionWidthOptions &&
                        <div style={getStyle(90000011)}>
                            <Select
                                className="mb-2"
                                options={sectionWidthOptions.map(cat => {return {value: cat, label: cat}})}
                                onChange={obj => {setAspectRatio(null); setSectionWidth(obj.value)}}
                                value={sectionWidth == null ? null : {value: sectionWidth, label: sectionWidth}}
                                styles={{'z-index': -1}}
                                menuPlacement="auto"
                                menuPosition="fixed"
                                placeholder="Select Section Width"
                            />
                        </div>
                    }
                    {
                        aspectRatioOptions &&
                        <div style={getStyle(90000012)}>
                            <Select
                                className="mb-2"
                                options={aspectRatioOptions.map(cat => {return {value: cat, label: cat}})}
                                onChange={obj =>  setAspectRatio(obj.value)}
                                value={aspectRatio == null ? null : {value: aspectRatio, label: aspectRatio}}
                                styles={{'z-index': -2}}
                                menuPlacement="auto"
                                menuPosition="fixed"
                                placeholder="Select Aspect Ratio"
                            />
                        </div>
                    }
                    <Stack spacing={1} style={getStyle()} alignItems="center" justifyContent="center" direction="row">
                        <Link to='/' style={{width: '50%'}}>
                            <Button variant="contained" style={{width: '100%', backgroundColor: '#C9D2D7', color: 'black'}} onClick={resetSpecSearch}>   
                                <ClearIcon/> Clear
                            </Button>
                        </Link>
                        <Link to={findURL} style={{width: '50%'}}>
                            <Button variant="contained" style={{width: '100%', backgroundColor: '#C9D2D7', color: 'black'}}>  
                                <SearchIcon/> Search 
                            </Button>
                        </Link>
                    </Stack>
                </div>
                <div className="flex flex-col center mt-5 mb-5">
                    <h2 className="searchLabelText" style={getStyle()}>Find by Category</h2>
                    <div style={getStyle(90000000)}>
                        <Select
                            className="mb-2"
                            options={allCategories.map(cat => {return {value: cat, label: cat}})}
                            onChange={obj => setCategory(obj.value)}
                            value={category == null ? null : {value: category, label: category}}
                            styles={{'z-index': -1}}
                            menuPlacement="auto"
                            menuPosition="fixed"
                            placeholder="Select a Category"
                        />
                    </div>
                    <Stack spacing={1} style={getStyle()} alignItems="center" justifyContent="center" direction="row">
                        <Link to='/' style={{width: '50%'}}>
                            <Button variant="contained" style={{width: '100%', backgroundColor: '#C9D2D7', color: 'black'}} onClick={() => setCategory(null)}>   
                                <ClearIcon/> Clear
                            </Button>
                        </Link>
                        <Link to={findURL} style={{width: '50%'}}>
                            <Button variant="contained" style={{width: '100%', backgroundColor: '#C9D2D7', color: 'black'}}>  
                                <SearchIcon/> Search 
                            </Button>
                        </Link>
                    </Stack>
                </div>
                <div className="flex flex-col mt-5 mb-5">
                    <h2 className="searchLabelText">&nbsp;</h2>
                    <Stack spacing={1} alignItems="center" justifyContent="center" direction="column">
                        <Button variant="outlined" style={{width: 'fit-content', height: 'fit-content', backgroundColor: '#C9D2D7', color: 'black'}}>   
                            <Link to="/OrderPreview">
                                <ShoppingCartIcon/>View Cart ({cartTotal} Items)
                            </Link>
                        </Button>
                        <Button variant="outlined" className="flex flex-col mt-1"
                            style={{width: 'fit-content', height: 'fit-content', backgroundColor: '#C9D2D7', color: 'black'}}>   
                            <Link to="/OtherProducts">
                                Other Products
                            </Link>
                        </Button>
                    </Stack>
                </div>
            </div>
        </div>
    )
}