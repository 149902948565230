import "../pages/home.css"
import React, { useEffect, useCallback } from 'react';
import { fetchCart, addToCart, itemInCart, getZOHOImageURL, handleImageError } from "../utils";

export default function OtherProductsPage({ otherItems, setSuccessText, cartItems, setCartItems, setTireToEmail, setEmailText }) {
    const itemListRef = React.useRef();
    const [quantity, setQuantity] = React.useState({});

    const handleQuantityChange = (itemId, event) => {
        setQuantity(prevQuantity => ({
            ...prevQuantity,
            [itemId]: event.target.value,
        }));
    };

    const fetchCartItems = useCallback(() => {
        async function doFetch() {
            await fetchCart(setCartItems);
        }
        doFetch();
    }, [setCartItems]);
    useEffect(() => fetchCartItems() , [fetchCartItems]);

    const handleClick = (tire) => {
        const numQuantity = parseInt(quantity[tire.itemId]);
        if (parseFloat(tire.quantity) < 1) {
            setTireToEmail(tire);
        } else if (!isNaN(numQuantity) && numQuantity > tire.quantity) {
            setTireToEmail(tire);
            setEmailText("We do not have this amount of tire in stock. You can either reduce the amount, or place an order using the following form:");
        } else if (isNaN(numQuantity)){
            addToCart(tire.itemId, 1, setCartItems); 
            setSuccessText('Added to Cart!');
        } else {
            addToCart(tire.itemId, numQuantity, setCartItems);
            setSuccessText('Added to Cart!');
        }
    };

    const containerStyle = {height: '480px', width: '350px'}

    return <div>
        <section className="frontpage" style={{ backgroundColor: '#EDEDED'}}>
        <div className="flex flex-wrap justify-center items-start" ref={itemListRef}>
            {otherItems.map(otherItem => (
                <div key={otherItem.itemId} className="max-w-sm m-2 bg-white rounded overflow-hidden shadow-lg" style={containerStyle}>
                    <h2 className="w-full pt-5 pl-5 pr-5 center font-bold text-xl">
                        {otherItem.itemName}
                    </h2>
                    <p className="text-xl center">${otherItem.price}</p>
                    <p className="m-2 center">
                        <input
                            type="number"
                            value={quantity[otherItem.itemId]}
                            onChange={(e) => handleQuantityChange(otherItem.itemId, e)}
                            placeholder="1"
                            className="w-16 h-10 px-2 rounded border focus:outline-none focus:border-blue-500 text-xl"
                        />
                        <button onClick={() => handleClick(otherItem)} className="h-10 px-2 bg-green-500 text-white font-bold rounded text-xl"
                            disabled={itemInCart(otherItem.itemId, cartItems)}>
                            {itemInCart(otherItem.itemId, cartItems) ? "Added!" : "Add to Cart"}
                        </button>
                    </p>
                    <div className='w-full flex justify-center'>
                    <img src={getZOHOImageURL(otherItem)} onError={handleImageError} alt="tire" className="searchPageTire"></img>
                    </div>
                </div>
            ))}
            </div>
        </section>
    </div>
}